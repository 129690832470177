import React from "react"
// import { Image } from "antd";
import Logo from '../../images/logo.png'
import AuthImage from '../../images/auth-image.png'

class AuthLayout extends React.Component {
  render(){
    return (
      <main>
        <div className="auth-layout">
          <div className="image-section">
            <img src={AuthImage} alt="AuthImage" preview="false"/>
          </div>
          <div className="auth-section">
            <div className="brand-logo">
              <img src={Logo} alt="brand-logo"/>
            </div>
            <div className="form-section">
              {this.props.children}
            </div>
          </div>
        </div>
      </main>
    )
  }
}
export default AuthLayout;