import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import {  useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ProtectedRoute from './protectedRoute';
import { LoguOutUser } from '../Redux/Actions/loginActions';
const Login = React.lazy(() => import('../Containers/authentication'));

//import Login from '../Containers/authentication';
//import Dashboard from '../Containers/Homepage';
const TwoFactor = React.lazy(() => import ('../Containers/twoFactor'));
const Dashboard = React.lazy(() => import ('../Containers/dashboard'));
const Inventory = React.lazy(() => import ('../Containers/inventory'));
const Usermanagement = React.lazy(() => import ('../Containers/userManagement'));
const Profile = React.lazy(() => import ('../Containers/profile'));
const Addseller = React.lazy(() => import ('../Containers/addSeller'));
const Sumdata = React.lazy(() => import ('../Containers/sumData'));
const UploadSellerData = React.lazy(() => import ('../Containers/uploadSellerData'));
const UploadInventory = React.lazy(() => import ('../Containers/UploadInventory'));
const ViewInventory = React.lazy(() => import ('../Containers/ViewInventory'));
const ViewProjection = React.lazy(() => import ('../Containers/ViewProjection'));
const UploadLargeSellerData = React.lazy(() => import ('../Containers/uploadSellerlargeData'));
const ViewSeller = React.lazy(() => import ('../Containers/viewSeller'));
const EditSeller = React.lazy(() => import ('../Containers/editSeller'));
const DeletePreview = React.lazy(() => import ('../Containers/deletePreview'));
const PageNotFound = React.lazy(() => import ('../Containers/404'));
const Forbidden = React.lazy(() => import ('../Containers/forbidden'));

const AddUser = React.lazy(() => import ('../Containers/addUser'));
const EditUser = React.lazy(() => import ('../Containers/editUser'));
const EmailVerify = React.lazy(() => import ('../Containers/verifyEmail'));
const ForgotPassword = React.lazy(() => import ('../Containers/forgotPassword'));
const ResetPassword = React.lazy(() => import ('../Containers/resetPassword'));
const MyRoutes = () => {
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.loginReducer);
  const token = user.tokens?.access;
  let navigate = useNavigate(); 
// console.log(user.tokens.access.token)
    
//     const expires1 = moment.utc(new Date('Mon May 09 2022 23:34:00 GMT+0500 (Pakistan Standard Time)' )).valueOf();
// const expireTime=expires1<=moment.utc(new Date()).valueOf()
// const expireTime=true
// console.log(expireTime,"usman",expires1,moment.utc(new Date()).valueOf())
// const tim=expires1
useEffect(() => {
  // console.log(user,' 123', window.location.href.includes('auth'))   
  if((!user || Object.keys(user).length === 0) && !window.location.href.includes('auth'))  {
    // return window.location('/auth/login')
    navigate('/auth/login')
  } 
}, []);
  return(
    <Routes>
      <Route path="/auth/login" element={
        !user?.user ? <React.Suspense fallback={<span></span>}>
          <Login/>
          </React.Suspense> : (token ? <Navigate to="/" /> : <Navigate to="/auth/two-factor" replace />)
      } />

      <Route path="/auth/setup-account" element={
        <React.Suspense fallback={<span>...Loading</span>}>
          <EmailVerify />
        </React.Suspense>
        }
      />
      <Route path="/auth/two-factor" element={
        <React.Suspense fallback={<span>...Loading</span>}>
          <TwoFactor />
        </React.Suspense>
        }
      />
      <Route path="/auth/forgot-password" element={
        <React.Suspense fallback={<span>...Loading</span>}>
          <ForgotPassword />
        </React.Suspense>
        }
      />

      <Route path="/auth/reset-password" element={
         !user?.user ? <React.Suspense fallback={<span>...Loading</span>}>
           <ResetPassword />
       </React.Suspense> :  <React.Suspense fallback={<span>...Loading</span>}>
          <Forbidden />
        </React.Suspense>
        }
      />
      {/* <Route path="/auth/reset-password" element={
        <React.Suspense fallback={<span>...Loading</span>}>
          <ResetPassword />
        </React.Suspense>
        }
      /> */}

      <Route>
        <Route path="/" element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Dashboard />
          </React.Suspense>   
          } 
        />
        <Route path="/inventory" element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Inventory />
          </React.Suspense>   
          } 
        />
        <Route path="/user-management" element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Usermanagement />
          </React.Suspense>   
          } 
        />
        <Route path="/profile" element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Profile />
          </React.Suspense>   
          } 
        />
        <Route path="/sumdata" element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Sumdata />
          </React.Suspense>
          }
        />
        {/* <Route path="/addseller" element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <Addseller />
          </React.Suspense>
          }
        />
        <Route path="/adduser" element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <AddUser />
          </React.Suspense>
          }
        />
        
        <Route path="/edituser/:id" element={
          <React.Suspense fallback={<span>...Loading</span>}>
            <EditUser />
          </React.Suspense>
          }
        /> */}
                      
        <Route path="/:id" element={
          <React.Suspense fallback={<span>...Loading</span>}>
            < UploadSellerData/>
          </React.Suspense>
          }
        />
        <Route path="/inventory/:id" element={
          <React.Suspense fallback={<span>...Loading</span>}>
            < UploadInventory/>
          </React.Suspense>
          }
        />
        
        <Route path="/inventory/:id/view-inventory" element={
          <React.Suspense fallback={<span>...Loading</span>}>
            < ViewInventory/>
          </React.Suspense>
          }
        />
        
        <Route path="/inventory/:id/view-projection" element={
          <React.Suspense fallback={<span>...Loading</span>}>
            < ViewProjection/>
          </React.Suspense>
          }
        />
        
        <Route path="/:id/upload-large-file" element={
          <React.Suspense fallback={<span>...Loading</span>}>
            < UploadLargeSellerData/>
          </React.Suspense>
          }
        />
        <Route path="/:id/viewseller" element={
          <React.Suspense fallback={<span>...Loading</span>}>
            < ViewSeller/>
          </React.Suspense>
          }
        />
        {/* <Route path="/editseller/:id" element={
          <React.Suspense fallback={<span>...Loading</span>}>
            < EditSeller/>
          </React.Suspense>
          }
        /> */}
        <Route path="/delete-preview" element={
          <React.Suspense fallback={<span>...Loading</span>}>
            < DeletePreview/>
          </React.Suspense>
          }
        />
        <Route path="*" element={
          <React.Suspense fallback={<span>...Loading</span>}>
            < PageNotFound/>
          </React.Suspense>
          }
        />
      </Route>
    </Routes>
  )
}

export default MyRoutes;
