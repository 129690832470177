import React, { Component } from 'react';
import 'antd/dist/antd.css';
// import './App.scss';
import './Styles.scss';
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import Authentication from './Containers/authentication';
import Header from './sharedModules/header'
import { Store, persistor } from './Redux/Store';
import MyRoutes from "./Routes";



class App extends Component {
  render() {
    return (
      <Provider store={Store}>
        <PersistGate loading={null} persistor={persistor}>
 
        <BrowserRouter>
          {/* <Header /> */}
          <MyRoutes />
          {/* <Authentication /> */}
       </BrowserRouter>

       </PersistGate>
      </Provider>
    );
  }

}

export default App;