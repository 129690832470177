import React,{useEffect,useState} from 'react';
import { Typography, Row, Col, Form, Input, Button } from 'antd';
import axios from 'axios';
import Swal from 'sweetalert2'
import { setUser } from '../../Redux/Actions/loginActions';
import {  useSelector,useDispatch } from 'react-redux';
import {useNavigate, Link} from "react-router-dom";
import AuthLayout from '../../sharedModules/authlayout'
import OtpInput from 'react-otp-input';
import { LoguOutUser } from '../../Redux/Actions/loginActions';


const { Title } = Typography;
const TwoFactor = () => {
  
  const {user} = useSelector(state => state.loginReducer);
  const [recoveryScreen, setRecoveryScreen] = useState(false);
  const [otpVal, setOtpVal] = useState('');

  let navigate = useNavigate(); 
  const dispatch = useDispatch();
  

  const onFinish = (values) => {
    // verify_tfa
    let form = {
      userId: user.user,
      code: otpVal
    }
    axios.post(`${process.env.REACT_APP_BASE_URL}/auth/verify_tfa`,form)
    .then((response) => {
      if(response.data.tokens) {
        navigate('/')
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message,
          showConfirmButton: false,
          timer: 3500
        })
      }
      return dispatch(setUser(response.data));
    })
    .catch((error)=> {
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: error.response.status,
        text: error.response.data.message,
        showConfirmButton: false,
        timer: 3500
      })
    });
  }

  const recoveryCode = (values) => {
    let form = {
      email: values.email,
      code: values.code
    }
    axios.post(`${process.env.REACT_APP_BASE_URL}/auth/recover-account`,form)
    .then((response) => {  
      console.log(response, response.data.message)
      if(response.data.message === 'Invalid Code') {
        Swal.fire({
          icon: 'error',
          text: response.data.message,
          showConfirmButton: false,
          timer: 3500
        })
      } else {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
          showConfirmButton: false,
          timer: 3500
        })
        navigate('/auth/login')
        return dispatch(setUser(response.data));
      }
    })
    .catch((error)=> {
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: error.response.status,
        text: error.response.data.message,
        showConfirmButton: false,
        timer: 3500
      })
    });
  }
  
  const recoveryCodeFailed = (error) => {
    console.log('error', error)
  }

  const showRecoveryScreen = () => {
    setRecoveryScreen(true)
  }
  
  const handleChange = (otp) => {
    setOtpVal(otp)
  };

  const makeUserLogout = () => {
    dispatch(LoguOutUser());
    navigate('/auth/login')
  }

  // handleChange = otp => this.setState({ otp });

  return (
    <AuthLayout>
      <Row type="flex" align="middle" className="formsRow">
        <Button type="outline-dark" className='logout-btn' onClick={makeUserLogout}>Logout</Button>
        {!recoveryScreen ?
          <Col className="formColumn" span="24">
            <Title level={4} className='text-center'>Two Factor Authentication</Title>
              
            <Form name="basic" layout="vertical" initialValues={{ remember: true }}
              onFinish={onFinish} autoComplete="off">
              <Form.Item label="Two Factor Code" name="code">
                {/* <Input /> */}
                <div className='otp-input'>
                  <OtpInput value={otpVal} onChange={handleChange} numInputs={6} separator='' />
                </div>
              </Form.Item>
              <Button type="primary" htmlType="submit" block size='lg'>Submit</Button>
              <p className='fw-700 text-center mt-4'>If want to use recovery code <Link to="#" type="secondary" shape="round" size="large" onClick={showRecoveryScreen}>Click here</Link> </p>
            </Form>
          </Col> : <Col className="formColumn" span='24'>
            <Title level={4} className='text-center'>Enter Recovery Code</Title>
              
            <Form name="basic" layout="vertical" initialValues={{ remember: true }}
              onFinish={recoveryCode} onFinishFailed={recoveryCodeFailed} autoComplete="off">
              <Form.Item label="User Email" name="email" rules={[{ required: true, message: 'Please input your email!' }]} >
                <Input/>
              </Form.Item>
              <Form.Item label="Recovery Code" name="code">
                <Input />
              </Form.Item>
              <Button type="primary" htmlType="submit" block size='lg'>
                Submit
              </Button>
              <p className='text-center mt-4'>Incase if your recovery code is also misplaced kindly contact administrator. </p>
            </Form>
          </Col>
        }
        
      </Row>
    </AuthLayout>
  )
}

export default TwoFactor