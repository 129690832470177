import React, {useEffect, useState} from "react";

import { Layout, Button, Row, Col, Dropdown, Menu } from "antd";
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from "react-redux";
import { LoguOutUser } from "../../Redux/Actions/loginActions";
import { Link } from "react-router-dom";
import TwoFactor from "../../Containers/twoFactor";
import {useNavigate} from "react-router-dom";
import Logo from '../../images/logo-white.png'
const { Header } = Layout;

const Index = () => {
  let navigate = useNavigate(); 
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.loginReducer);
  const token = user.tokens?.access;
  const [userinfo, setUserinfo] = useState([]);
  const cookies = new Cookies();
  
  const logoutUser = () => {
    navigate('/auth/login')
    dispatch(LoguOutUser())
  }
  useEffect(() => {
    if(user.user) {
      setUserinfo(user.user)
    } 
    if(cookies.get('profileUpdated')) {
      setUserinfo(cookies.get('profileUpdated'))
    }
  }, [user])
  return (
    <header>
      <div className="brand-logo">
        <Link to="/">
          <img src={Logo} alt="brand-logo"/>
        </Link>
      </div>
      {
        (user.user && !token) ? <Button className="btn-auto" size="small" onClick={logoutUser}>Logout</Button> : ''
      }
      { token?.token   ? 
        // <div className="menus ml-auto">
        //   <Link className="" size="large" to="/addseller">
        //     Add a Seller
        //   </Link>
        //   <Link className="" size="large" to="/sumdata"> 
        //     Reports
        //   </Link>  
        // </div>
        <Dropdown placement="bottomLeft" trigger={['click']} overlay={
          <Menu>
            <Menu.Item key="1" style={{background:"#fff"}}>
              <Button className="" type="link" href="/profile" size="small">My Profile</Button>
            </Menu.Item>
            <Menu.Item key="2" style={{background:"#fff"}}>
              <Button className="" type="link" size="small" onClick={logoutUser}>Logout</Button>
            </Menu.Item>
          </Menu>
        }>
          <Button type="transparent" className="user-profile">
            <i className="name-icon">{userinfo?.name?.charAt(0)}</i>
            <em className="text-left">
              <strong>{userinfo?.name}</strong> <br/>
              <span>({userinfo?.role})</span>
            </em>
          </Button>
        </Dropdown> :""
      }
    </header>
  );
};

export default Index;
