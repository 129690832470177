import { SET_USER,LOGOUT_USER,EXAL_DATA } from "../Actions/loginActions";

const initialState = {
    user: '',
    delData:''
}

function loginReducer(state = initialState, action){
    switch (action.type) {

        case SET_USER:
        return {...state, user:action.payload}
        case LOGOUT_USER:
            
        return {...state, user:action.payload,delData:action.payload}
        case EXAL_DATA:
            
        return {...state, delData:action.payload}

        default:
        return state
    }
}

export default loginReducer;