import { Navigate, Outlet, useLocation } from 'react-router-dom';
import React,{useEffect, useState} from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { LoguOutUser } from '../Redux/Actions/loginActions';
import { type } from '@testing-library/user-event/dist/type';

const ProtectedRoutes = ({token}) => {

  const dispatch = useDispatch()
  const expires1 = moment.utc(new Date(token?.expires)).valueOf();
const expireTime=+expires1  <  +moment.utc(new Date()).valueOf() 
  useEffect(() => {
    
    if(expireTime){
      
      // localStorage.clear()
        dispatch(LoguOutUser())
    }
    
});
  const location = useLocation();
  
  let token1 = token?.token;
  return token1 
    ? <Outlet />
    : <Navigate to="/" replace state={{ from: location }} />;
}

export default ProtectedRoutes


/* For User Role */

// const currentRole = JSON.parse(localStorage.getItem("role"));
//   if (currentRole === role) {
//     return <Route {...rest} />;
//   } else {
//     return (
//       <Redirect
//         to={{
//           pathname: currentRole ? "/" : "/login",
//           state: {
//             from: rest.location
//           }
//         }}
//       />
//     );
//   }