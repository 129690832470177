
import Cookies from 'universal-cookie';
export const EXAL_DATA = "EXAL_DATA";
export const SET_USER = "SET_USER";
export const LOGOUT_USER = "LOGOUT_USER";
const cookies = new Cookies();

// user => payload
//dispatch => hook -> receiver in action file.

// For login
export const setUser = user => dispatch =>{
    dispatch({
        type:SET_USER,
        payload: user
    })
}
export const deleteDataPrev = delData => dispatch =>{
    dispatch({
        type:EXAL_DATA,
        payload: delData
    })
}
export const LoguOutUser = user => dispatch =>{
    // localStorage.clear()
    cookies.remove('profileUpdated')
    dispatch({
        type:LOGOUT_USER,
        
        payload: {},
    })

}
