import React,{useEffect,useState} from 'react';
import { Form, Input, Button, Checkbox,Row, Col } from 'antd';
import Swal from 'sweetalert2'
import { Typography } from 'antd';
import { connect } from 'react-redux';

// import { userActions } from '../../Redux/Actions';
// import apiMethod from '../../configs'
import { LoguOutUser, setUser } from '../../Redux/Actions/loginActions';
import {  useSelector,useDispatch } from 'react-redux';
import axios from 'axios';
import AuthLayout from '../../sharedModules/authlayout'
const { Title } = Typography;
const Demo = (props) => {
    const  [statusCode, setStatusCode] = useState("")
    const {user} = useSelector(state => state.loginReducer);
    const dispatch = useDispatch();
    // const {user} = JSON.parse(localStorage.getItem("loginReducer"))
    

    // const dummyData = {
    //     "user": {
    //         "role": "user",
    //         "isEmailVerified": false,
    //         "email": "imran.mushtaq@arpatech.com",
    //         "name": "Imran Mushtaq",
    //         "id": "625558c4eebd8b16f346942e"
    //     },
    //     "tokens": {
    //         "access": {
    //             "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MjU1NThjNGVlYmQ4YjE2ZjM0Njk0MmUiLCJpYXQiOjE2NTA0NTA1ODMsImV4cCI6MTY1MDQ2ODU4MywidHlwZSI6ImFjY2VzcyJ9.Ql5kgT-q7BwiT8oiImOe6wpaxNVNwJSGG406-U65XBM",
    //             "expires": "2022-04-20T15:29:43.848Z"
    //         },
    //         "refresh": {
    //             "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MjU1NThjNGVlYmQ4YjE2ZjM0Njk0MmUiLCJpYXQiOjE2NTA0NTA1ODMsImV4cCI6MTY3NjM3MDU4MywidHlwZSI6InJlZnJlc2gifQ.k1pKRXUePbwwIej292lccg_Ar28YWVofMUgmRFBpzWE",
    //             "expires": "2023-02-14T10:29:43.848Z"
    //         }
    //     }
    // }

    const onFinish = (values) => {
          axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`,values).then(function (response) {
            if(response.data.status == 401){
                return Swal.fire({
                    icon: 'error',
                    title: response.status,
                    text: response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                    // footer: '<a href="">Why do I have this issue?</a>'
                  })
            }
            if(response.data.user.role === undefined) dispatch(LoguOutUser());
           return dispatch(setUser(response.data));
          })
          .catch((error)=> {
            if(error.response.data.user) {
                return dispatch(setUser(error.response.data));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: error.response.status,
                    text: error.response.data.message,
                    showConfirmButton: false,
                    timer: 3500
                    // footer: '<a href="">Why do I have this issue?</a>'
                })
                if(error.response.status== 401){
                    dispatch(LoguOutUser());
                }
            }          
            // if (error.response.status == 401) {
            //     setStatusCode(error.response.data.message)
            //       }
            });

// dummyData.user['access_token'] = dummyData?.tokens.access.token
    //    localStorage.setItem('access_token',dummyData.tokens.access.token);
       
        
    //    if(user.access_token){
    //     // <Navigate to="/dashboard" replace />
    //     navigate('/dashboard')
    //    }
    
    //   const { dispatch } = props;
    // dispatch(userActions.login("abc", "test"));
      
    };

    const onFinishFailed = (errorInfo) => {
        
    };
    return (
      <AuthLayout>
        <Row type="flex" align="middle">
          <Col className="formColumn" span={24}>
            <Form name="basic" layout="vertical" initialValues={{ remember: true }} 
              onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off" >
              <Form.Item label="Username" name="email" 
                rules={[{ required: true, message: 'Please input your username!' }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Password" name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}>
                <Input.Password />
              </Form.Item>
              <Form.Item className='mb-0'>
                <Button type="primary" htmlType="submit" block>
                  Submit
                </Button>
              </Form.Item>
              <Form.Item className='text-center'>
                <a className="text-danger fw-500 fs-18" href="/auth/forgot-password">Forgot password</a>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </AuthLayout>
    );
};

export default Demo;