import { createStore,applyMiddleware, combineReducers, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


import thunk from 'redux-thunk';
//import rootReducer from '../Reducers';
import loginReducer from '../Reducers/loginReducer';


const persistConfig = {
  key: 'root',
  storage,
}






// const store = createStore(rootReducer);
// const store = createStore(rootReducer,applyMiddleware(thunk));
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({loginReducer});
const persistedReducer = persistReducer(persistConfig, rootReducer)
export const Store = createStore(persistedReducer, composeEnhancers(
    applyMiddleware(thunk)));

    export const persistor = persistStore(Store)
    //return { store, persistor }   

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(rootReducer, composeEnhancers(
//   applyMiddleware(thunk)));

// export default store;